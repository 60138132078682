<template>
    <div>
        <!-- 轮播图 -->
        <!-- <template v-if="type == 1"> -->
        <div v-for="el in list" :key="el.id" class="content">
            <div>
                <div class="upImg">
                    <span>{{ el.title }}</span>
                    <upImg :src="el.image" @removeSrc="el.image = ''" ref="img" :id="el.id"></upImg>
                </div>
                <span><i class="el-icon-right" style="font-size: 28px; margin: 0 10px"></i></span>
                <div v-for="e in el.children" :key="e.id">
                    <div class="upImg">
                        <span>{{ e.title }}</span>
                        <upImg :src="e.image" @removeSrc="e.image = ''" ref="img" :id="e.id"></upImg>
                    </div>
                </div>
            </div>
            <div style="display: flex">
                <el-input v-model="el.url" />
                <el-select v-model="el.type" placeholder="请选择" clearable>
                    <el-option label="共享" :value="0"> </el-option>
                    <el-option label="电脑端" :value="1"> </el-option>
                    <el-option label="手机端" :value="2"> </el-option>
                </el-select>
            </div>
        </div>
        <!-- 坑位图 -->
        <!-- <template v-if="type == 0"> </template> -->
    </div>
</template>

<script>
import upImg from "../../customs/uploadImg.vue";
import axios from "axios";

export default {
    components: { upImg },
    props: {
        list: Array,
        type: Number,
    },
    data() {
        return {};
    },
    mounted() {
        // console.log(this.list);
    },
    methods: {
        getData() {
            return this.$refs["img"].map(e => {
                let u = this.list.find(el => el.id == e.id);
                return {
                    // 第一次上传的图片会被存在temporary文件夹下，则去除头部域名
                    // image: e.url.indexOf("temporary") != -1 ? e.url.replace(axios.defaults.baseURL, "") : e.url,
                    image: e.url,
                    id: e.id,
                    url: u && u.url ? "http://" + u.url : "",
                    type: u && u.type ? u.type : "",
                };
            });
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    & > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }
    .el-input {
        width: 400px;
    }
}
.upImg {
    position: relative;
    & > span {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}
</style>
